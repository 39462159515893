:root {
    --green: #057a55;
    --orange: #e70;
    --red: #b1030c;
    --black: #19191e;
    --darkgrey: #272626;
    --grey: #4d4d4d;
}

button.blurred {
    pointer-events: none;
    opacity: 0.5;
}

.line-through {
    text-decoration: line-through;
}

.consolidatedFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.selected-text {
    font-weight: bold;
}

.modalAttributes li button {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black);
    color: white;
    border: 0;
    margin-left: 10px;
    cursor: pointer;
}

.modalDescription1.crossed + .modalDescription1 {
   border-top: 1px solid var(--grey);
   padding-top: 10px;
   margin-top: 20px;
}

.modalDescription1.crossed + .modalDescription1.crossed {
    border-top: 0;
    margin-top: 0;
}

.modalDescription1.crossed {
    opacity: 0.5;
    line-height: 1;
}

.modalAttributes li button .sjefIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}


.modalAttributes li button .sjefIcon svg {
    width: 20px;
    height: 20px;
    color: var(--white);
}

.setStatus {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
}

.setStatus .selectOption {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    padding: 8px 12px;
    background: var(--grey)
}

.setStatus .selectOption.selected {
    background: var(--green);
}

.setStatus .selectOption:first-child {
    margin-left: 0;
}


.modalAttributes li {
   margin-bottom: 10px;
   font-weight: bold;
   font-size: 14px;
}

.modalAttributes li:last-child {
    margin-bottom: 0;
}

.options .option {
    margin-bottom: 12px;
}

.options .option:last-child {
    margin-bottom: 0;
}

.options .option.active {
    font-weight: bold;
    position: relative;
    display: inline-block;
}

.options .option.active:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(10px, -50%);
    width: 10px;
    height: 10px;
    background: var(--green);
    border-radius: 100%;
}

.custom-select-container .options {
    border: 1px solid rgba(255,255,255,0.7);
    border-radius: 8px;
    background: var(--black);
    margin-bottom: 20px;
    padding: 12px;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex.start {
    align-items: flex-start;
}

.flex.between {
    width: 100%;
    justify-content: space-between;
}

.buttonFlexer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.buttonFlexer button {
    width: calc(50% - 5px);
    background: var(--orange);
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
}

.buttonFlexer button:first-child {
    margin-right: 10px;
}

@media screen and (max-width: 450px) {
    .mobileMain span.active::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: white;
    }
}

@media screen and (max-width: 768px) {
    .webSiteVersion {
        padding: 10px;
    }
    .divdiv {
        padding: 10px;
    }
    .sortFilters {
        color: white;
    }
    /*=============================== cui-overlay cui-toaster cui-toaster-top =============================== */
    .cui-overlay {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        top: calc(5% + 0px);
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
    }
    .cui-toast {
        /* position: fixed; */
        background-color: var(--green);
        /* left: 50%; */
        /* transform: translateX(-50%); */
        /* top: calc(5% + 0px); */
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 200px;
        padding: 15 15px;
        color: white;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }
    .timerSelectZone {
        background-color: var(--green);
        color: #000;
    }
    .printModeSelectZone {
        background-color: var(--orange);
        color: #000;
    }

    /*=============================== cui-overlay cui-toaster cui-toaster-top =============================== */

    .language {
        padding-right: 5px;
    }
    .language-menu-container {
        position: relative;
        display: inline-block;
    }

    .language-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        list-style-type: none;
        padding-left: 10px;
        margin: 0;
        display: flex;
        padding: 15px;
    }

    .menu-toggle {
        color: #fff;
        cursor: pointer;
        background-color: #ccc;
        border: none;
        border-radius: 4px;
        padding: 8px 16px;
        height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu-toggle:hover {
        background-color: #0056b3;
    }

    /* -----------------------------popover------------------------------ */
    .custom-popover-container {
        position: relative;
        display: inline-block;
    }
    .custom-popover-content {
        position: absolute;
        top: 100%;
        right: 0;
        /* background: white; */
        border-radius: 5px;
        border: 1px solid #ddd;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }

    /* -----------------------------popover------------------------------ */

    .cui-icon-chevron-down {
        display: none;
    }

    .theme-light .modalDialog {
        background-color: white;
    }
    .middlePart {
        background-image: url('../assets/images/sjef_logo-white.png');
        background-size: 84px;
        background-position-x: center;
        background-position-y: center;
        width: 84px;
        height: 29px;
        display: flex;
    }

    .theme-light .middlePart {
        background-image: url('../assets/images/sjef_logo.png');
        background-size: 84px;
        background-position-x: center;
        background-position-y: center;
    }
    .thema-light .modalDialog {
        background-color: white;
    }
    /*---------------------------------------- switchmode ---------------------------------------- */
    .modeSwitcher {
        display: flex;
        flex-direction: row-reverse;
        align-content: center;
        align-items: center;
        justify-content: flex-end;
    }

    .switchMode {
        position: relative;
        display: inline-block;
        width: 50px;
        margin-right: 10px;
        height: 23px;
    }

    .switchMode input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider1 {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider1:before {
        position: absolute;
        content: '';
        height: 30px;
        width: 30px;
        left: 0px;
        bottom: 4px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        box-shadow: 0 0px 15px #2020203d;
        background-repeat: no-repeat;
        background-position: center;
    }

    input:checked + .slider1 {
        background-color: #2196f3;
    }

    input:focus + .slider1 {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider1:before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
        background-repeat: no-repeat;
        background-position: center;
    }

    /* Rounded sliders */
    .slider1.round1 {
        border-radius: 34px;
    }

    .slider1.round1:before {
        border-radius: 50%;
        background-color: gray;
    }
    /*---------------------------------------- switchmode ---------------------------------------- */

    /*---------------------------------------- switchblacktheme  ---------------------------------------- */

    .switch {
        position: relative;
        display: inline-block;
        width: 55px;
        height: 27px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 35px;
        width: 35px;
        left: 0px;
        bottom: 4px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        box-shadow: 0 0px 15px #2020203d;
        background: white url('https://i.ibb.co/FxzBYR9/night.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    input:checked + .slider {
        background-color: #2196f3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
        background: white url('https://i.ibb.co/7JfqXxB/sunny.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
        background-color: gray;
    }

    /*---------------------------------------- switchblacktheme  ---------------------------------------- */
    .theme-light .order1,
    .theme-light .order2,
    .theme-light .order3 {
        color: #000000;
    }

    .order1,
    .order2,
    .order3,
    .order4 {
        color: #fff;
        display: flex;
        width: -webkit-fill-available;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .theme-light .modalChoice {
        color: black;
    }
    .theme-light .modalDialog {
        border: solid 1px;
        /* background-color: rgba(0, 0, 0, 0); */

        background-color: white;
    }
    .theme-light h3 {
        color: black;
    }
    .theme-light .footerTime {
        color: black;
        background-color: white;
    }
    .theme-light .footerTime {
        color: black;
        background-color: white;
    }
    .theme-light .groceries {
        color: black;
    }
    .theme-light {
        background-color: white;
        color: black;
    }
    .theme-light .customerReadyOrders {
        background-color: white;
    }
    .theme-light .modalPRSPart {
        background-color: white;
        color: black;
    }
    .theme-light .modalReadyPart {
        background-color: white;
        color: black;
    }
    .theme-light .modalPRSOrders {
        background-color: white;
    }
    .theme-light .header {
        background-color: white;
        color: black;
    }

    .theme-light .container-wrapper {
        background-color: white;
        color: black;
    }
    .theme-light .container {
        background-color: white;
        border-color: #000000;
        color: #000000;
    }

    .theme-light .orderItem {
        background-color: white;
        border: solid 1px;
        border-color: #000000;
        color: #000000;
    }

    .theme-light .modalCloseButton {
        border: 1px solid #000;
    }

    .theme-light .upModalPart {
        background-color: white;
        color: black;
    }
    .theme-light body {
        background-color: white;
    }
    .theme-light .parkedContainerMain {
        background-color: white;
        color: black;
    }
    .theme-light .ordersContainer {
        background-color: white;
        color: black;
    }
    .theme-light .footer {
        background-color: white;
        color: black;
    }
    .theme-light .footerNavBar {
        background-color: white;
        color: black;
    }

    .parderDiv {
        display: flex;
        justify-content: space-around;
    }
    .orderTable {
        display: inline-block; /* Дозволяє елементу бути вирівняним в одному ряду з іншими блочними елементами */
        margin-right: 10px; /* Задає простір між елементами */
    }

    .orderStatusIf {
        display: inline-block; /* Також дозволяє елементу бути вирівняним в одному ряду */
    }

    .orderTimestamp {
        flex: 1;
        /* padding: 4px; */
        text-align: center;
    }

    .footerNavBar h3.active::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--orange);
    }
    /*------------------------------------------- modeCustomer -------------------------------*/

    .customerPrepareOrders {
        border-radius: 5px;
        align-content: flex-start;
        flex-wrap: wrap;
        display: flex;
        overflow-x: hidden;
        overflow-y: scroll;
        flex-direction: column;
        width: 50%;
        /* height: 100%; */
        height: 910px;

        background-color: #2b2b31;
    }

    .customerPrepareOrders::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .customerready::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .customerready {
        border-radius: 5px;
        flex-flow: row wrap;
        align-content: flex-start;
        width: 50%;
        padding: 10px;
        display: flex;
        overflow: hidden scroll;
        box-sizing: border-box;
    }
    .mainCustomerPart {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .upCustomerPart {
        display: flex;
        justify-content: space-between;
        /* width: 100%; */
        padding-top: 20px;
    }
    .customerPart {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 20px;
        /* height: 500px; */
    }

    .customerLeftPart {
        font-size: 40px;
        border-radius: 5px;
        /* border: 2px solid black; */
        background-color: #ee7700;
        padding: 10px;
        margin-right: 10px;
        width: 50%;
        color: white;
        text-align: center;
    }
    .customerRightPart {
        font-size: 40px;
        border-radius: 5px;
        background-color: var(--green);
        padding: 10px;
        width: 50%;
        color: white;
        text-align: center;
    }

    .customerPreparePart {
        background-color: #1f1c1c;
        border: 1px solid var(--orange);
        margin-left: 4px;
        border-radius: 5px;
        color: white;
        padding: 10px;
        margin-top: 10px;
        display: flex;
        padding: 4px 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        /* width: 150px; */
        /* height: 130px; */
        font-size: 50px;
        overflow: hidden;
    }

    .customerReadyPart {
        background-color: #1f1c1c;
        border: 1px solid var(--green);
        margin-left: 4px;
        border-radius: 5px;
        color: white;
        padding: 10px;
        margin-top: 10px;
        display: flex;
        padding: 4px 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        /* width: 150px; */
        /* height: 130px; */
        font-size: 50px;
        overflow: hidden;
    }

    /*------------------------------------------- modeCustomer -------------------------------*/
    .kdsModePage {
        padding: 8px;
    }
    .modalChoiceBody {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border-radius: 5px;
        z-index: 1000;
        flex: 0 0 auto;
        width: 100%;
    }
    .modalChoiceTitle {
        padding-bottom: 30px;
        text-align: center;
        font-size: 22px;
    }
    .logout {
        background-color: var(--orange);
        color: #fff;
        border: none;
        border-radius: 50px;
        width: 100px;
        margin-top: 40px;
        padding: 10px 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;
    }
    .modalDescription1 {
        font-size: 18px;
        line-height: 2;
    }

    .modalDescription {
        font-size: 18px;
        line-height: 1.6;
        /* color: #333; */
        text-align: center;
        display: flex;
    }

    .customerButton {
        padding: 5px 6px;
        background-color: #ee7700;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 110px;
        height: 80px;
    }
    .customerButton .sjefIcon svg {
        width: 40px;
        height: 40px;
    }
    .theme-light .customerPrepareOrders {
        background-color: white;
    }
    .theme-light .customerPreparePart {
        background-color: white;
        color: #000;
    }
    .theme-light .customerReadyPart {
        background-color: white;
        color: #000;
    }
    /* var(--green); */
    .kitchenButton {
        padding: 5px 6px;
        background-color: var(--green);
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 110px;
        height: 80px;
    }

    .divov {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; /* Вирівнювання по горизонталі */
        text-align: center; /* Вирівнювання тексту всередині блоку */
    }

    .groceries {
        color: #fce96a;
        font-size: 11px;
    }
    .groceriesRow {
        font-size: 12px;
    }
    .footerTime {
        color: white;
        flex-basis: 350px;
        justify-content: flex-start;
        width: 350px;
        max-width: 350px;
        padding: 1em;
        display: flex;
        font-size: 11px;
    }
    .theme-light .groceriesRow {
        color: red;
    }

    /*---------------------------------- webContainer container serve orders .ivan /---------------------------------- */

    .theme-light .container-wrapper {
        background-color: white;
        color: black;
    }

    .container-wrapper {
        display: flex;
        flex-direction: column;
    }

    .main-containers {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 4px 0;
        max-width: 100%;
        width: 100%;
    }

    .main-containers::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .containerFrame {
        display: flex;
        flex-direction: column;
        width: inherit;
        height: 100%;
    }
    .container {
        display: flex;
        flex-direction: column;
        width: 210px;
        height: 500px;
        margin-right: 5px;
        padding: 0;
        color: #f0f0f0;
        background-color: #272626;
        position: relative;
        border: 2px solid #bbbbbb;
        border-radius: 5px;
        box-sizing: border-box;
    }
    .container.containerA {
        /* border-style: dashed; */
        border-style: solid;
    }

    .container.containerA.almostDanger {
        border-color: var(--orange);
    }

    .container.containerA.danger {
        border-color: var(--red);
    }

    .container:last-child {
        margin-bottom: 100px;
    }
    .orderStatus.colorOfButton.open {
        color: var(--orange);

    }
    .orderStatus.colorOfButton.prepare {
        color: var(--green);

    }
    .lowOrderRightButton button.colorOfButton.open {
        background-color : var(--orange);
    }
    .lowOrderRightButton button.colorOfButton.prepare {
        background-color: var(--green);
    }

    .upPart {
        box-sizing: border-box;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        padding: 10px;
        display: flex;
        /* height: 25%; */
    }
    .lowPart {
        box-sizing: border-box;
        flex-grow: 1;
        padding: 10px;
        display: flex;
        /* height: 75%; */
        flex-direction: column;
        overflow-y: hidden;
    }

    .lowOrderMain {
        flex-direction: column;
        height: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
    }

    .orderWrapper {
        flex-grow: 1;
        overflow-y: auto;
        /* height: 80%; */
    }

    .order {
        width: 100%;
        /* padding-bottom: 10px; */
        padding-left: 0;
        /* height: 100%; */
        /* margin: auto; */
    }

    .orderWrapper::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .lowOrderMainOrders {
        /* overflow: hidden; */
        /* white-space: nowrap; */
        font-size: 16px;
    }

    .notes {
        color: #fce96a;
        font-size: 16px;
    }

    .lowOrder {
        grid-gap: 5px;
        flex-direction: row-reverse;

        padding: 0;
        display: flex;
    }
    .lowOrderRightButton {
        position: relative;
        right: auto;
        bottom: auto;
        width: 100%;
        flex-grow: 1;
        height: 40px;
    }
    .lowOrderRightButton button {
        padding: 5px 10px;
        background-color: var(--green);
        color: white;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 100%;
        height: 100%;
    }

    .lowOrderLeftButton button {
        padding: 5px 10px;
        background-color: #4d4d4d;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 100%;
        height: 100%;
    }
    .lowOrderLeftButton {
        position: relative;
        right: auto;
        bottom: auto;
        left: auto;
        width: 100%;
        height: 100%;
    }

    .idContainer,
    .orderName,
    .orderTime,
    .orderStatus {
        flex: 1;
        padding: 4px;
        text-align: center;
    }

    .serve-containers {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 4px 0;
        max-width: 100%;
        width: 100%;
    }

    .serve-containers::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    /*---------------------------------- webContainer container serve orders/---------------------------------- */
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: #19191e;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        overflow: hidden;
    }

    .main {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        height: 100%;
    }
    html {
        background-color: #19191e;
        overflow: hidden;
        padding: 2px;
        width: 100%;
        height: 100%;
        padding-top: 30px;
    }

    h1,
    h3,
    h2 {
        color: white;
        margin: 0%;
    }
    ul {
        list-style-type: none;
    }

    .header {
        padding-top: 25px;
        color: #fff;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .headerFull {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 30px;
        color: white;
        width: 100%;
    }
    .leftPart {
        flex: 1;
    }

    .rightPart {
        text-align: right;
        flex-direction: row-reverse;
        flex: 1;
        display: flex;
        align-items: baseline;
    }
    .settings {
        position: relative;
    }

    .idContainer,
    .orderName,
    .orderTime {
        flex: 1;
        padding: 4px;
        text-align: center;
    }
    .orderStatus {
        flex: 1;
        /* padding: 4px; */
        text-align: center;
    }
    .idContainer {
        font-size: 22px;
    }

    /* ========================= footerNa=================================== */

    /* ========================= footerNa=================================== */

    /* select  */
    .custom-select {
        min-width: 350px;
        position: relative;
    }
    select {
        width: -webkit-fill-available;
        appearance: none;
        color: #000;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #caced1;
        border-radius: 0.25rem;
        padding: 0.675em 6em 0.675em 1em;
        font-size: 1.15rem;
    }

    .custom-select::before,
    .custom-select::after {
        --size: 0.3rem;
        content: '';
        position: absolute;
        right: 1rem;
        pointer-events: none;
    }

    .custom-select::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid black;
        top: 40%;
    }

    .custom-select::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid black;
        top: 55%;
    }

    /* slect */
    /* ======================== .allOrders ======================================= */
    .consolidatedOrders {
        position: fixed;
        top: 65;
        right: 0;
        padding: 1px 0;
        width: 220px;
        height: 80%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .consolidatedOrderMain {
        width: 100%;
        height: 100%;
        margin-right: 5px;
        padding: 0;
        color: #f0f0f0;
        background-color: #272626;
        align-items: center;
        justify-items: center;
        position: relative;
        border-radius: 5px;
        box-sizing: border-box;
    }
    .theme-light .consolidatedOrderMain {
        color: black;
        background-color: #fff;
        border: solid 1px black;
    }

    .consolidatedOrder {
        font-size: 16px;
    }

    /* ======================== .consolidatedOrders ======================================= */

    .containeFrame {
        position: absolute;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        border-radius: 5px;
    }

    .orderDescriptionServe {
        height: 370px;
    }

    .ordersList::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .ordersList {
        display: flex;
        flex-wrap: wrap;
        overflow-x: scroll;
        overflow-y: scroll;
        padding: 4px 0;
        max-width: 100%;
        /* width: 88%; */
        /* height: 110px; */
    }
    .container.active-container {
        /* border-color: #faca15;  */
        border: 2px solid #faca15;
    }

    .ordersReopenButton button {
        padding: 5px 10px;
        background-color: #4d4d4d;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        /* width: 80px; */
        height: 30px;
    }
    .orderItem {
        flex: 0 0 auto;
        width: 210px;
        height: 110px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 0;
        color: #f0f0f0;
        background-color: #272626;
        position: relative;
        /* border-style: dashed; */
        border-style: solid;
        border-color: var(--orange);

        border-radius: 5px;
        box-sizing: border-box;
    }
    .ordersReopenButton {
        text-align: center;
    }

    .orderItem::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .readyOrder::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .readyOrder {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 4px 0;
        max-width: 100%;
        width: 100%;
    }

    .readyOrderMain::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .idContainer {
        margin-bottom: 1px;
    }

    .readyServeButton button {
        padding: 5px 10px;
        background-color: #4d4d4d;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 145px;
        height: 40px;
        margin-top: 15px;
        margin-left: 48px;
    }
    .orderStatusServe {
        flex: 1;
        padding: 5px;
        height: 40px;
        text-align: center;
    }

    form {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        align-items: center;
    }
    .form-field {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /* Signin container */
    .signin-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: #19191e;
        flex-direction: column;
    }

    /* Form container */
    .form-containr {
        max-width: 400px;
        width: 100%;
        padding: 20px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
    }

    /* Logo */
    .logo {
        width: 100px;
        height: auto;
        display: block;
        margin: 0 auto;
    }

    /* Signin heading */
    .signin-heading {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
        color: white;
    }

    /* Form fields */
    .input-field {
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        margin-top: 20px;
        padding: 15px;
    }
    .form-fields {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /* Submit button */
    .submit-button {
        color: #fff;
        cursor: pointer;
        background-color: #4caf50;
        border: none;
        border-radius: 5px;
        width: 100%;
        margin-top: 20px;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
    }

    .submit-button:hover {
        background-color: #45a049;
    }

    .submit-button:active {
        background-color: #3e8e41;
    }

    .header-title {
        justify-content: flex-end;
        padding: 1em;
        display: flex;
        flex-basis: 350px;
        width: 350px;
        max-width: 350px;
    }

    .footer .prepareFooterButton,
    .footer .readyFooterButton {
        margin-left: 10px;
    }
    .prepareFooterButton button {
        padding: 10px 20px;
        background-color: var(--orange);
        color: white;

        border: none;
        cursor: pointer;
        border-radius: 50px;
        font-size: 12px;
        /* width: 80px; */
        /* height: 50px; */
    }
    .readyFooterButton button {
        padding: 5px 10px;

        color: white;
        background-color: var(--green);
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 80px;
        height: 50px;
    }
    .footer {
        position: fixed;
        display: flex;
        bottom: 0;
        left: 0;

        background-color: #272626;
        width: 100%;
        height: 80px;
        align-items: center;
        justify-content: space-between;
    }

    .footerNavBar {
        color: #fff;
        display: flex;
        flex-grow: 1;
        height: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    /* ---------------------------modal ------------------------- */
    .modalGroceries {
        color: #fce96a;
    }
    .modalAttributes {
        background-color: #272626;
        border-radius: 5px;
        padding: 10px;
    }

    .theme-light .modalAttributes {
        background-color: #fff;
    }

    .modalOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        /* background-color: rgba(0, 0, 0, 0.5); */
        z-index: 999;
    }
    /* background-color: #272626; */
    .modalDialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #1a1a1a;
        padding: 20px;
        border-radius: 5px;
        z-index: 1000;
        flex: 0 0 auto;
        width: 85vw;
        min-height: 300px;
        border: 1px solid;
        display: flex;
        flex-direction: column;
    }

    .modalDialog .modalBody {
        flex-grow: 1;
    }

    .modalDialog .modalFooter button:first-child:last-child {
        width: 100%;
        border-radius: 50px;
        color: white;
        font-weight: bold;
        background: var(--orange);
        border: 2px solid var(--orange);
    }

    .modalHeader {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
    }
    .modalKDSHeader {
        flex-flow: wrap;
        padding-top: 10px;
        place-content: center space-between;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        flex-direction: row-reverse;
    }

    .modalTitle {
        margin: 0;
        color: white;
    }

    .orderLines {
        list-style-type: none;
        padding: 0;
        color: white;
    }
    .modalChoiceFooter {
        display: flex;
        bottom: 10;
        /* flex-direction: row-reverse; */
        justify-content: center;
    }
    .modalFooter {
        text-align: right;

        display: flex;
        bottom: 10;
        flex-direction: row-reverse;
    }

    .modalCloseButton {
        padding: 5px 6px;
        background-color: white;
        cursor: pointer;
        border-radius: 5px;
        width: 80px;
        height: 40px;
    }
   /*------------------------------------------ modalSetting------------------------------------------- */

   .modalAttributes {
    background-color: #272626;
    border-radius: 5px;
    padding: 10px;
}

.theme-light .modalAttributes {
    background-color: #fff;
}

.modalSettingsOverlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 999;
}
/* background-color: #272626; */
.modalSettingsDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    flex: 0 0 auto;
    width: 80vw;
    min-height: 300px;
    border: 1px solid var(--green);
    display: flex;
    flex-direction: column;
}
.modalSettingsTitlePrint,
.modalSettingsHeader {
    flex-flow: column wrap;
    place-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
    display: flex;
    background-color: #272626;
    border-radius: 5px;
}
.modalDialog .modalBody {
    flex-grow: 1;
}

.modalDialog .modalFooter button:first-child:last-child {
    width: 100%;
    border-radius: 50px;
    color: white;
    font-weight: bold;
    background: var(--orange);
    border: 2px solid var(--orange);
}

.modalSettingsTitle {
    /* margin: 0; */
    color: white;
    margin-bottom: 10px;
}

.modalSettingsOptions {
    /* width: 100%; */
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}

.modalSettingsOptions .selectOption {
    /* border: 1px solid var(--green); */
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    box-sizing: border-box;
    place-content: center;
    width: 80px;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #272626;
    background: var(--black);
    border-radius: 50px;
    color: white;
}


.modalSettingsOptions .selectOption:nth-child(3n) {
    margin-right: 0;

}

.modalSettingsOptions .selectOption.selected {
    background: var(--green);
}

.modalSettingsOptionsLarge {
    /* width: 100%; */
    width: 100%;
    display: inline-flex;
    box-sizing: border-box;
    width: 300px;
    border-radius: 50px;
    background: var(--black);
    margin-bottom: 20px;

}

.modalSettingsOptionsLarge .selectOption {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
}

.modalSettingsOptionsLarge .selectOption.selected {
    background: var(--orange);
    border-radius: 50px;
}

.modalSettingsFooter {
    display: flex;
    bottom: 10;
    /* flex-direction: row-reverse; */
    justify-content: center;
}
/* --------------------------------------------modalSettings -------------------*/


    /*------------------------- prepare PRS---------------------------- */
    .modalId {
        font-size: 24px;
    }

    .modalPRSOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }

    .modalPRSDialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #272626;
        padding: 20px;
        border-radius: 5px;
        max-width: 80%;
        max-height: 80%;
        overflow: auto;
        z-index: 1000;
        flex: 0 0 auto;
    }

    .modalPRSHeader {
        margin-bottom: 10px;
    }

    .modalPRSTitle {
        margin: 0;
        color: white;
    }

    .modalPRSBody {
        padding-bottom: 10px;
        color: white;
    }

    .modalPRSFooter {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .modalPRSFooter button {
        flex: 1;
    }

    .modalPRSCloseButton {
        padding: 5px 6px;
        background-color: var(--green);
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 85px;
        height: 40px;
        margin-right: 10px;
    }
    .modalPRSActionButton {
        padding: 5px 6px;
        background-color: var(--orange);
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 85px;
        height: 40px;
    }
    /* ----------------------------------------modalUser----------------------------------------*/

    .userMessage {
        display: flex;
        justify-content: center;
        font-size: 35px;
    }
    .modalUserOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.03);
        z-index: 999;
    }

    .modalUserDialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #272626;
        padding: 20px;
        border-radius: 5px;
        max-width: 80%;
        max-height: 80%;
        overflow: auto;
        z-index: 1000;
        flex: 0 0 auto;
        width: 400px;
    }

    .modalUserHeader {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .modalUserTitle {
        margin: 0;
        color: white;
    }

    .modalUserBody {
        padding-bottom: 10px;
        color: white;
    }

    .modalUserFooter {
        text-align: right;
        display: flex;
        justify-content: space-around;
    }

    .modalUserCloseButton {
        padding: 5px 6px;
        background-color: white;
        color: #4d4d4d;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 80px;
        margin-right: 10px;
        height: 40px;
    }
    .modalUserNoButton {
        padding: 5px 6px;
        background-color: red;

        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 100px;
        height: 60px;
    }
    .modalUserYesButton {
        padding: 5px 6px;
        background-color: #34d399;

        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 100px;
        height: 60px;
    }
}

@media screen and (min-width: 769px) {
    /*------------------------------------------ modalSetting------------------------------------------- */

    .modalAttributes {
        background-color: #272626;
        border-radius: 5px;
        padding: 10px;
    }

    .theme-light .modalAttributes {
        background-color: #fff;
    }

    .theme-light .selectOption,
    .theme-light .modalSettingsTitle {
        color: white;
    }

    .theme-light .options {
        background: lightgrey;
    }

    .modalSettingsOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        /* background-color: rgba(0, 0, 0, 0.5); */
        z-index: 999;
    }
    /* background-color: #272626; */
    .modalSettingsDialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #1a1a1a;
        padding: 20px;
        border-radius: 5px;
        z-index: 1000;
        flex: 0 0 auto;
        width: 45vw;
        min-height: 300px;
        border: 1px solid;
        display: flex;
        flex-direction: column;
    }
    .modalSettingsTitlePrint,
    .modalSettingsHeader {
            flex-flow: column wrap;
            place-content: center;
            align-items: center;
            margin-bottom: 10px;
            padding-top: 10px;
            display: flex;
            background-color: #272626;
            border-radius: 5px;
        }

        .modalSettingsHeader:last-child {
            margin-bottom: 0;
        }
    .modalDialog .modalBody {
        flex-grow: 1;
    }

    .modalDialog .modalFooter button:first-child:last-child {
        width: 100%;
        border-radius: 50px;
        color: white;
        font-weight: bold;
        background: var(--orange);
        border: 2px solid var(--orange);
    }

    .modalSettingsTitle {
        /* margin: 0; */
        color: white;
        margin-bottom: 10px;

    }

    .modalSettingsOptions {
        /* width: 100%; */
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 460px;
    }

    .modalSettingsOptions .selectOption {
        /* border: 1px solid var(--green); */
        padding: 5px 10px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        box-sizing: border-box;
        place-content: center;
        width: 80px;
        border-radius: 5px;
        padding: 5px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: #272626;
        background: var(--black);
        border-radius: 50px;
        color: white;
    }


    .modalSettingsOptions .selectOption:nth-child(5n) {
        margin-right: 0;

    }

    .modalSettingsOptions .selectOption.selected {
        background: var(--green);
    }

    .modalSettingsOptionsLarge {
        /* width: 100%; */
        width: 100%;
        display: inline-flex;
        box-sizing: border-box;
        width: 300px;
        border-radius: 50px;
        background: var(--black);
        margin-bottom: 20px;

    }

    .modalSettingsOptionsLarge .selectOption {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-weight: bold;
        font-size: 14px;
    }

    .modalSettingsOptionsLarge .selectOption.selected {
        background: var(--orange);
        border-radius: 50px;
    }

    .modalSettingsFooter {
        display: flex;
        bottom: 10;
        /* flex-direction: row-reverse; */
        justify-content: center;
    }
    /* --------------------------------------------modalSettings -------------------*/

    .sortButton {
        height: 30px;
        background: transparent;
        border: 0;
        color: var(--orange);
    }

    .divdiv {
        padding: 10px;
    }

    .sortFilters {
        color: white;
    }

    /*=============================== cui-overlay cui-toaster cui-toaster-top =============================== */
    .cui-overlay {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        top: calc(5% + 0px);
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
    }
    .cui-toast {
        /* position: fixed; */
        background-color: var(--green);
        /* left: 50%; */
        /* transform: translateX(-50%); */
        /* top: calc(5% + 0px); */
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 200px;
        padding: 15 15px;
        color: white;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }
    .timerSelectZone {
        background-color: var(--green);
        color: #000;
    }
    .printModeSelectZone {
        background-color: var(--orange);
        color: #000;
    }

    /*=============================== cui-overlay cui-toaster cui-toaster-top =============================== */

    .language-menu-container {
        position: relative;
        display: inline-block;
    }

    .language-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        list-style-type: none;
        padding-left: 10px;
        margin: 0;
    }

    .menu-toggle {
        color: #fff;
        cursor: pointer;
        background-color: #ccc;
        border: none;
        border-radius: 4px;
        padding: 8px 16px;
        height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu-toggle:hover {
        background-color: #0056b3;
    }

    /* -----------------------------popover------------------------------ */
    .custom-popover-container {
        position: relative;
        display: inline-block;
    }
    .custom-popover-content {
        position: absolute;
        top: 100%;
        right: 0;
        /* background: white; */
        border-radius: 5px;
        border: 1px solid #ddd;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }

    /* -----------------------------popover------------------------------ */

    .cui-icon-chevron-down {
        display: none;
    }

    .theme-light .modalDialog {
        background-color: white;
    }

    .thema-light .modalDialog {
        background-color: white;
    }
    /*---------------------------------------- switchmode ---------------------------------------- */
    .modeSwitcher {
        display: flex;
        flex-direction: row-reverse;
        align-content: center;
        align-items: center;
        justify-content: flex-end;
    }
    .switchMode {
        position: relative;
        display: inline-block;
        width: 50px;
        margin-right: 10px;
        height: 23px;
    }

    .switchMode input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider1 {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider1:before {
        position: absolute;
        content: '';
        height: 30px;
        width: 30px;
        left: 0px;
        bottom: 4px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        box-shadow: 0 0px 15px #2020203d;
        background-repeat: no-repeat;
        background-position: center;
    }

    input:checked + .slider1 {
        background-color: #2196f3;
    }

    input:focus + .slider1 {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider1:before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
        background-repeat: no-repeat;
        background-position: center;
    }

    /* Rounded sliders */
    .slider1.round1 {
        border-radius: 34px;
    }

    .slider1.round1:before {
        border-radius: 50%;
        background-color: gray;
    }
    /*---------------------------------------- switchmode ---------------------------------------- */
    /*---------------------------------------- switchblacktheme  ---------------------------------------- */
    .switch {
        position: relative;
        display: inline-block;
        width: 55px;
        height: 27px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 35px;
        width: 35px;
        left: 0px;
        bottom: 4px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        box-shadow: 0 0px 15px #2020203d;
        background: white url('https://i.ibb.co/FxzBYR9/night.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    input:checked + .slider {
        background-color: #2196f3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
        background: white url('https://i.ibb.co/7JfqXxB/sunny.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
        background-color: gray;
    }
    .theme-light .order1,
    .theme-light .order2,
    .theme-light .order3 {
        color: #000000;
    }

    .theme-light .order {
        color: #000000;
    }

    .order {
        color: white;
        flex: 1;
    }

    /* The slider */
    .slider1 {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .theme-light .modalChoice {
        color: black;
    }
    .theme-light .modalDialog {
        border: solid 1px;
        /* background-color: rgba(0, 0, 0, 0); */

        background-color: white;
    }
    .theme-light h3 {
        color: black;
    }
    .theme-light .footerTime {
        color: black;
        background-color: white;
    }
    .theme-light .footerTime {
        color: black;
        background-color: white;
    }
    .theme-light .groceries {
        color: black;
    }
    .theme-light {
        background-color: white;
        color: black;
    }
    .theme-light .customerReadyOrders {
        background-color: white;
    }
    .theme-light .modalPRSPart {
        background-color: white;
        color: black;
    }
    .theme-light .modalReadyPart {
        background-color: white;
        color: black;
    }
    .theme-light .modalPRSOrders {
        background-color: white;
    }
    .theme-light .header {
        background-color: white;
        color: black;
    }

    .theme-light .container-wrapper {
        background-color: white;
        color: black;
    }
    .theme-light .container {
        background-color: white;
        border-color: #000000;
        color: #000000;
    }

    .theme-light .orderItem {
        background-color: white;
        border: solid 1px;
        border-color: #000000;
        color: #000000;
    }

    .theme-light .modalCloseButton {
        border: 1px solid #000;
    }

    .theme-light .upModalPart {
        background-color: white;
        color: black;
    }
    .theme-light body {
        background-color: white;
    }
    .theme-light .parkedContainerMain {
        background-color: white;
        color: black;
    }
    .theme-light .ordersContainer {
        background-color: white;
        color: black;
    }
    .theme-light .footer {
        background-color: white;
        color: black;
    }
    .theme-light .footerNavBar {
        background-color: white;
        color: black;
    }

    .parderDiv {
        display: flex;
        justify-content: space-around;
    }
    .orderTable {
        display: inline-block; /* Дозволяє елементу бути вирівняним в одному ряду з іншими блочними елементами */
        margin-right: 10px; /* Задає простір між елементами */
    }

    .orderStatusIf {
        display: inline-block; /* Також дозволяє елементу бути вирівняним в одному ряду */
    }

    .orderTimestamp {
        flex: 1;
        /* padding: 4px; */
        text-align: center;
    }

    .footerNavBar h3.active::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--orange);
    }
    /*------------------------------------------- modeCustomer -------------------------------*/

    .customerPrepareOrders {
        border-radius: 5px;
        align-content: flex-start;
        flex-wrap: wrap;
        display: flex;
        overflow-x: hidden;
        overflow-y: scroll;
        flex-direction: column;
        width: 50%;
        /* height: 100%; */
        height: 910px;

        background-color: #2b2b31;
    }

    .customerPrepareOrders::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .customerready::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .customerready {
        border-radius: 5px;
        flex-flow: row wrap;
        align-content: flex-start;
        width: 50%;
        display: flex;
        overflow: hidden scroll;
    }
    .mainCustomerPart {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .upCustomerPart {
        display: flex;
        justify-content: space-between;
        /* width: 100%; */
        padding-top: 20px;
    }
    .customerPart {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 20px;
        /* height: 500px; */
    }

    .customerLeftPart {
        font-size: 40px;
        border-radius: 5px;
        /* border: 2px solid black; */
        background-color: #ee7700;
        padding: 10px;
        margin-right: 10px;
        width: 50%;
        color: white;
        text-align: center;
    }
    .customerRightPart {
        font-size: 40px;
        border-radius: 5px;
        background-color: var(--green);
        padding: 10px;
        width: 50%;
        color: white;
        text-align: center;
    }

    .customerPreparePart {
        background-color: #1f1c1c;
        border: 1px solid#ee7700;
        margin-left: 4px;
        border-radius: 5px;
        color: white;
        padding: 10px;
        margin-top: 10px;
        display: flex;
        padding: 4px 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 130px;
        font-size: 60px;
        overflow: hidden;
    }

    .customerReadyPart {
        background-color: #1f1c1c;
        border: 1px solid var(--green);
        margin-left: 10px;
        border-radius: 5px;
        color: white;
        padding: 10px;
        margin-top: 10px;
        display: flex;
        padding: 4px 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: calc(25% - 12px);
        font-size: 60px;
        overflow: hidden;
    }

    /*------------------------------------------- modeCustomer -------------------------------*/
    .kdsModePage {
        padding: 8px;
    }
    .modalChoiceBody {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border-radius: 5px;
        z-index: 1000;
        flex: 0 0 auto;
        width: 500px;
    }
    .modalChoiceTitle {
        padding-bottom: 30px;
        text-align: center;
        font-size: 22px;
    }
    .logout {
        border: none;
        background-color: var(--orange);
        color: white;
        margin-top: 40px;
        /* height: 60px; */
        width: 100px;
        border-radius: 50px;
        padding: 10px 20px;
        font-weight: bold;
    }
    .modalDescription1 {
        font-size: 18px;
        line-height: 2.5;
    }
    .modalDescription {
        font-size: 18px;
        line-height: 1.6;
        /* color: #333; */
        text-align: center;
        display: flex;
    }

    .customerButton {
        padding: 5px 6px;
        background-color: #ee7700;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 110px;
        height: 80px;
    }
    .customerButton .sjefIcon svg {
        width: 40px;
        height: 40px;
    }
    .theme-light .customerPrepareOrders {
        background-color: white;
    }
    .theme-light .customerPreparePart {
        background-color: white;
        color: #000;
    }
    .theme-light .customerReadyPart {
        background-color: white;
        color: #000;
    }
    /* var(--green); */
    .kitchenButton {
        padding: 5px 6px;
        background-color: var(--green);
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 110px;
        height: 80px;
    }

    .divov {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; /* Вирівнювання по горизонталі */
        text-align: center; /* Вирівнювання тексту всередині блоку */
    }

    .groceries {
        color: #fce96a;
        font-size: 11px;
    }
    .groceriesRow {
        font-size: 12px;
    }
    .footerTime {
        color: white;
        flex-basis: 350px;
        justify-content: flex-start;
        width: 350px;
        max-width: 350px;
        padding: 1em;
        display: flex;
        font-size: 12px;
        /* background:  */
    }

    .footerTime .footerTimeInner {
        padding: 10px 20px;
        border-radius: 50px;
        background: var(--green);
        font-weight: bold;
    }





    .theme-light .footerTime .footerTimeInner {
        color: white;
    }

    .theme-light .groceriesRow {
        color: red;
    }

    /*---------------------------------- webContainer container serve orders .ivan /---------------------------------- */

    .container-wrapper {
        display: flex;
        flex-direction: column;
    }
    .main-containers {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        align-content: flex-start;
        overflow-wrap: scroll;
        padding: 4px 0;
        max-width: 100%;
        width: 100%;
        height: 88vh;
    }

    .webSiteVersion.open .main-containers {
        width: calc(100% - 220px);
    }

    .main-containers::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .colored {
        background: blue;
    }

    .containerFrame {
        display: flex;
        flex-direction: column;
        width: inherit;
        height: 100%;
    }

    .container {
        flex: 0 0 auto;
        width: 210px;
        height: 500px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 0;
        color: #f0f0f0;
        background-color: #272626;
        position: relative;
        border: 2px solid #bbbbbb;
        border-radius: 5px;
        box-sizing: border-box;
    }
    .container.containerA {
        /* border-style: dashed; */
        border-style: solid;
    }

    .container.containerA.almostDanger {
        border-color: var(--orange);
    }

    .container.containerA.danger {
        border-color: var(--red);
    }

    .container:last-child {
        margin-bottom: 100px;
    }
    .orderStatus.colorOfButton.open {
        color: var(--orange);

    }
    .orderStatus.colorOfButton.prepare {
        color: var(--green);

    }
    .lowOrderRightButton button.colorOfButton.open {
        background-color : var(--orange);
    }
    .lowOrderRightButton button.colorOfButton.prepare {
        background-color: var(--green);
    }
    .upPart {
        box-sizing: border-box;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        padding: 10px;
        display: flex;
        /* height: 25%; */
    }
    .lowPart {
        box-sizing: border-box;
        flex-grow: 1;
        padding: 10px;
        display: flex;
        /* height: 75%; */
        flex-direction: column;
        overflow-y: hidden;
    }
    .lowOrderMain {
        flex-direction: column;
        height: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
    }

    .orderWrapper {
        flex-grow: 1;
        overflow-y: auto;
        /* height: 80%; */
    }
    .order {
        width: 100%;
        /* padding-bottom: 10px; */
        padding-left: 0;
        height: 100%;
        margin: auto;
    }
    .orderWrapper::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .lowOrderMainOrders {
        /* overflow: hidden; */
        /* white-space: nowrap; */
        font-size: 16px;
    }

    .notes {
        color: #fce96a;
        font-size: 16px;
    }
    .lowOrder {
        grid-gap: 5px;
        flex-direction: row-reverse;
        width: 100%;
        padding: 0;
        display: flex;
    }

    .lowOrderRightButton {
        width: 100%;
        flex-grow: 1;
        height: 100%;
    }
    .lowOrderRightButton button {
        padding: 5px 10px;
        background-color: var(--green);
        color: white;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 100%;
        height: 40px;
    }
    .lowOrderLeftButton {
        position: relative;
        right: auto;
        bottom: auto;
        left: auto;
        width: 40%;
        height: 100%;
    }
    .lowOrderLeftButton button {
        padding: 5px 10px;
        background-color: #4d4d4d;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 100%;
        height: 100%;
    }

    .serve-containers {
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        align-content: flex-start;
        overflow-wrap: scroll;
        padding: 4px 0;
        max-width: 100%;
        width: 100%;
        height: 88vh;
    }

    .serve-containers::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    /*---------------------------------- webContainer container serve orders/---------------------------------- */
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: #19191e;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        overflow: hidden;
    }

    .main {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        height: 100%;
    }
    html {
        background-color: #19191e;
        overflow: hidden;
        padding: 8px;
        width: calc(100% - 16px);
        height: 100%;
    }

    h1,
    h3,
    h2 {
        color: white;
        margin: 0%;
    }
    ul {
        list-style-type: none;
    }

    .header {
        color: #fff;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-top: 10px;
    }
    .headerFull {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 30px;
        color: white;
        width: 100%;
    }
    .leftPart {
        flex: 1;
        display: flex;
        align-items: center;
    }
    .middlePart {
        background-image: url('../assets/images/sjef_logo-white.png');
        background-size: 84px;
        background-position-x: center;
        background-position-y: center;
        width: 84px;
        height: 29px;
    }

    .theme-light .middlePart {
        background-image: url('../assets/images/sjef_logo.png');
        background-size: 84px;
        background-position-x: center;
        background-position-y: center;
    }

    .rightPart {
        flex: 1;
        text-align: right;
        align-items: center;
        display: flex;
        margin-right: 10px;
        flex-direction: row-reverse;
    }
    .settings {
        position: relative;
    }

    .idContainer,
    .orderName,
    .orderTime {
        flex: 1;
        padding: 4px;
        text-align: center;
    }
    .orderStatus {
        flex: 1;
        /* padding: 4px; */
        text-align: center;
    }
    .idContainer {
        font-size: 22px;
    }

    /* ========================= footerNa=================================== */
    .openOrderFooter {
        margin-right: 150px;
    }
    .parkedOrderFooter {
        margin-right: 150px;
    }

    /* ========================= footerNa=================================== */

    /* select  */
    .custom-select {
        min-width: 350px;
        position: relative;
    }

    select {
        width: -webkit-fill-available;
        appearance: none;
        color: #000;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #caced1;
        border-radius: 0.25rem;
        padding: 0.675em 6em 0.675em 1em;
        font-size: 1.15rem;
    }

    .custom-select::before,
    .custom-select::after {
        --size: 0.3rem;
        content: '';
        position: absolute;
        right: 1rem;
        pointer-events: none;
    }

    .custom-select::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid black;
        top: 40%;
    }

    .custom-select::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid black;
        top: 55%;
    }

    /* slect */
    /* ======================== .consolidatedOrders ======================================= */
    .consolidatedOrders {
        position: fixed;
        top: 65;
        right: 0;
        padding: 1px 0;
        width: 220px;
        height: 80%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .consolidatedOrderMain {
        width: 100%;
        height: 100%;
        margin-right: 5px;
        padding: 0;
        color: #f0f0f0;
        padding: 10px;
        background-color: #272626;
        align-items: center;
        justify-items: center;
        position: relative;
        border-radius: 5px;
        box-sizing: border-box;
    }

    .consolidatedOrderMain h3 {
        margin-bottom: 20px;
    }

    .theme-light .consolidatedOrderMain {
        color: black;
        background-color: #fff;
        border: solid 1px black;
    }

    .consolidatedOrder {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .consolidatedOrder:last-child {
        margin-bottom: 0;
    }

    /* ======================== .allOrders ======================================= */

    .containeFrame {
        position: absolute;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        border-radius: 5px;
    }

    .orderDescriptionServe {
        height: 370px;
    }

    .ordersList::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .ordersList {
        display: flex;
        flex-wrap: wrap;
        overflow-x: scroll;
        overflow-y: scroll;
        padding: 4px 0;
        max-width: 100%;
        /* width: 88%; */
        /* height: 110px; */
    }
    .container.active-container {
        /* border-color: #faca15;  */
        border: 2px solid #faca15;
    }

    .ordersReopenButton button {
        padding: 5px 10px;
        background-color: #4d4d4d;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        /* width: 80px; */
        height: 30px;
    }
    .orderItem {
        flex: 0 0 auto;
        width: 210px;
        height: 110px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 0;
        color: #f0f0f0;
        background-color: #272626;
        position: relative;
        /* border-style: dashed; */
        border-style: solid;
        border-color: var(--orange);

        border-radius: 5px;
        box-sizing: border-box;
    }
    .ordersReopenButton {
        text-align: center;
    }

    .orderItem::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .readyOrder::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .readyOrder {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 4px 0;
        max-width: 100%;
        width: 100%;
    }

    .readyOrderMain::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .idContainer {
        margin-bottom: 1px;
    }

    .readyServeButton button {
        padding: 5px 10px;
        background-color: #4d4d4d;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 145px;
        height: 40px;
        margin-top: 15px;
        margin-left: 48px;
    }
    .orderStatusServe {
        flex: 1;
        padding: 5px;
        height: 40px;
        text-align: center;
    }

    /* Signin container */
    .signin-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: #19191e;
        flex-direction: column;
    }

    /* Form container */
    .form-containr {
        max-width: 400px;
        width: 100%;
        padding: 20px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-top: 20px; /* Added margin-top to position the form below the logo */
    }

    /* Logo */
    .logo {
        width: 100px;
        height: auto;
        display: block;
        margin: 0 auto;
    }

    /* Signin heading */
    .signin-heading {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
        color: white;
    }

    /* Form fields */
    .input-field {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }

    /* Submit button */
    .submit-button {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #4caf50;
        color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
    }

    .submit-button:hover {
        background-color: #45a049;
    }

    .submit-button:active {
        background-color: #3e8e41;
    }

    .header-title {
        justify-content: flex-end;
        padding: 1em;
        display: flex;
        flex-basis: 350px;
        width: 350px;
        max-width: 350px;
    }

    .footer .prepareFooterButton,
    .footer .readyFooterButton {
        margin-left: 10px;
    }
    .prepareFooterButton button {
        padding: 10px 20px;
        background-color: var(--orange);
        color: white;

        border: none;
        cursor: pointer;
        border-radius: 50px;
        font-weight: bold;
        font-size: 12px;
        /* width: 80px; */
        /* height: 50px; */
    }
    .readyFooterButton button {
        padding: 5px 10px;

        color: white;
        background-color: var(--green);
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 80px;
        height: 50px;
    }
    .footer {
        background-color: #272626;
        place-content: center flex-start;
        width: 100%;
        height: 80px;
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        align-items: center;
        /* justify-content: center; */
        justify-content: space-between;
    }

    .footerNavBar {
        color: #fff;
        display: flex;
        font-size: 13px;
        flex-grow: 1;
        height: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    /* ---------------------------modal ------------------------- */
    .modalGroceries {
        color: #fce96a;
    }
    .modalAttributes {
        background-color: #272626;
        border-radius: 5px;
        padding: 10px;
    }

    .theme-light .modalAttributes {
        background-color: #fff;
    }

    .modalOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        /* background-color: rgba(0, 0, 0, 0.5); */
        z-index: 999;
    }
    /* background-color: #272626; */
    .modalDialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #1a1a1a;
        padding: 20px;
        border-radius: 5px;
        z-index: 1000;
        flex: 0 0 auto;
        width: 500px;
        min-height: 300px;
        border: 1px solid;
        display: flex;
        flex-direction: column;
    }

    .modalDialog .modalBody {
        flex-grow: 1;
    }

    .modalDialog .modalFooter button:first-child:last-child {
        width: 100%;
        border-radius: 50px;
        color: white;
        font-weight: bold;
        background: var(--orange);
        border: 2px solid var(--orange);
    }

    .modalHeader {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
    }
    .modalKDSHeader {
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .modalDescription .order {
        color: white;
        flex: 1;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        font-weight: bold;
    }

    .modalDescription .order button {
        margin-bottom: 10px;
    }

    .modalKDSHeader button {
        margin: 0;
        margin-right: 10px;
    }

    .modalTitle {
        margin: 0;
        color: white;
    }

    .orderLines {
        list-style-type: none;
        padding: 0;
        color: white;
    }
    .modalChoiceFooter {
        display: flex;
        bottom: 10;
        /* flex-direction: row-reverse; */
        justify-content: center;
    }
    .modalFooter {
        text-align: right;

        display: flex;
        bottom: 10;
        flex-direction: row-reverse;
    }

    .modalCloseButton {
        padding: 5px 6px;
        background-color: white;
        cursor: pointer;
        border-radius: 5px;
        width: 80px;
        height: 40px;
    }

    /*------------------------- prepare PRS---------------------------- */
    .modalId {
        font-size: 24px;
    }

    .modalPRSOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }

    .modalPRSDialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #272626;
        padding: 20px;
        border-radius: 5px;
        max-width: 80%;
        max-height: 80%;
        overflow: auto;
        z-index: 1000;
        flex: 0 0 auto;
        min-width: 400px;
    }
    .theme-light .modalPRSDialog {
        background-color: #fff;
    }

    .theme-light .modalDescription .order {
        color: var(--black);
    }

    .theme-light h4 {
        color: black;
    }
    .modalPRSHeader {
        margin-bottom: 10px;
    }

    .modalPRSTitle {
        margin: 0;
        color: white;
    }

    .modalPRSBody {
        padding-bottom: 10px;
        margin-bottom: 10px;
        color: white;
    }

    .modalPRSBody h4 {
        font-weight: 400;
    }

    .modalPRSBody span {
        font-size: 14px;
    }

    .modalPRSFooter {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .modalPRSFooter button {
        flex: 1;
        font-weight: bold;
        border-radius: 50px;
        text-transform: capitalize;
    }

    .modalPRSCloseButton {
        padding: 5px 6px;
        background-color: var(--green);
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 85px;
        height: 40px;
        margin-right: 10px;
    }
    .modalPRSActionButton {
        padding: 5px 6px;
        background-color: var(--orange);
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 85px;
        height: 40px;
    }
    /* ----------------------------------------modalUser----------------------------------------*/

    .userMessage {
        display: flex;
        justify-content: center;
        font-size: 35px;
    }
    .modalUserOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.03);
        z-index: 999;
    }

    .modalUserDialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #272626;
        padding: 20px;
        border-radius: 5px;
        max-width: 80%;
        max-height: 80%;
        overflow: auto;
        z-index: 1000;
        flex: 0 0 auto;
        width: 400px;
    }

    .modalUserHeader {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .modalUserTitle {
        margin: 0;
        color: white;
    }

    .modalUserBody {
        padding-bottom: 10px;
        color: white;
    }

    .modalUserFooter {
        text-align: right;
        display: flex;
        justify-content: space-around;
    }

    .modalUserCloseButton {
        padding: 5px 6px;
        background-color: white;
        color: #4d4d4d;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 80px;
        margin-right: 10px;
        height: 40px;
    }
    .modalUserNoButton {
        padding: 5px 6px;
        background-color: red;

        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 100px;
        height: 60px;
    }
    .modalUserYesButton {
        padding: 5px 6px;
        background-color: #34d399;

        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        width: 100px;
        height: 60px;
    }
}
/* ----------------------------------------modalUser----------------------------------------*/
/*------------------------- ready modal---------------------------- */

@media screen and (min-height: 1024px) {
    .container {
        flex: 0 0 auto;
        width: 210px;
        height: 650px;
        margin-right: 5px;
        padding: 0;
        color: #f0f0f0;
        background-color: #272626;
        position: relative;
        border: 2px solid #bbbbbb;
        border-radius: 5px;
        box-sizing: border-box;
    }
}
