:root {
  --green: #057a55;
  --orange: #e70;
  --red: #b1030c;
  --black: #19191e;
  --darkgrey: #272626;
  --grey: #4d4d4d;
}

button.blurred {
  pointer-events: none;
  opacity: .5;
}

.line-through {
  text-decoration: line-through;
}

.consolidatedFilter {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.selected-text {
  font-weight: bold;
}

.modalAttributes li button {
  background: var(--black);
  color: #fff;
  cursor: pointer;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-left: 10px;
  display: flex;
}

.modalDescription1.crossed + .modalDescription1 {
  border-top: 1px solid var(--grey);
  margin-top: 20px;
  padding-top: 10px;
}

.modalDescription1.crossed + .modalDescription1.crossed {
  border-top: 0;
  margin-top: 0;
}

.modalDescription1.crossed {
  opacity: .5;
  line-height: 1;
}

.modalAttributes li button .sjefIcon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.modalAttributes li button .sjefIcon svg {
  color: var(--white);
  width: 20px;
  height: 20px;
}

.setStatus {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
}

.setStatus .selectOption {
  background: var(--grey);
  border-radius: 50px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
}

.setStatus .selectOption.selected {
  background: var(--green);
}

.setStatus .selectOption:first-child {
  margin-left: 0;
}

.modalAttributes li {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}

.modalAttributes li:last-child {
  margin-bottom: 0;
}

.options .option {
  margin-bottom: 12px;
}

.options .option:last-child {
  margin-bottom: 0;
}

.options .option.active {
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.options .option.active:after {
  content: "";
  background: var(--green);
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  transform: translate(10px, -50%);
}

.custom-select-container .options {
  background: var(--black);
  border: 1px solid #ffffffb3;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 12px;
}

.flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex.start {
  align-items: flex-start;
}

.flex.between {
  justify-content: space-between;
  width: 100%;
}

.buttonFlexer {
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.buttonFlexer button {
  background: var(--orange);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.buttonFlexer button:first-child {
  margin-right: 10px;
}

@media screen and (width <= 450px) {
  .mobileMain span.active:after {
    content: "";
    background-color: #fff;
    width: 100%;
    height: 1px;
    display: block;
  }
}

@media screen and (width <= 768px) {
  .webSiteVersion, .divdiv {
    padding: 10px;
  }

  .sortFilters {
    color: #fff;
  }

  .cui-overlay {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    display: flex;
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
  }

  .cui-toast {
    background-color: var(--green);
    color: #fff;
    z-index: 1000;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: auto;
    padding: 15px;
    display: flex;
    box-shadow: 0 4px 8px #0000001a;
  }

  .timerSelectZone {
    background-color: var(--green);
    color: #000;
  }

  .printModeSelectZone {
    background-color: var(--orange);
    color: #000;
  }

  .language {
    padding-right: 5px;
  }

  .language-menu-container {
    display: inline-block;
    position: relative;
  }

  .language-menu {
    z-index: 1;
    margin: 0;
    padding: 15px;
    list-style-type: none;
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .menu-toggle {
    color: #fff;
    cursor: pointer;
    text-align: center;
    background-color: #ccc;
    border: none;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px 16px;
    display: flex;
  }

  .menu-toggle:hover {
    background-color: #0056b3;
  }

  .custom-popover-container {
    display: inline-block;
    position: relative;
  }

  .custom-popover-content {
    z-index: 10;
    border: 1px solid #ddd;
    border-radius: 5px;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 4px 8px #0000001a;
  }

  .cui-icon-chevron-down {
    display: none;
  }

  .theme-light .modalDialog {
    background-color: #fff;
  }

  .middlePart {
    background-image: url("sjef_logo-white.5051d464.png");
    background-position: center;
    background-size: 84px;
    width: 84px;
    height: 29px;
    display: flex;
  }

  .theme-light .middlePart {
    background-image: url("sjef_logo.331a9f06.png");
    background-position: center;
    background-size: 84px;
  }

  .thema-light .modalDialog {
    background-color: #fff;
  }

  .modeSwitcher {
    flex-direction: row-reverse;
    place-content: center flex-end;
    align-items: center;
    display: flex;
  }

  .switchMode {
    width: 50px;
    height: 23px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
  }

  .switchMode input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider1 {
    cursor: pointer;
    background-color: #ccc;
    transition: all .4s;
    position: absolute;
    inset: 0;
  }

  .slider1:before {
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin: auto 0;
    transition: all .4s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 15px #2020203d;
  }

  input:checked + .slider1 {
    background-color: #2196f3;
  }

  input:focus + .slider1 {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider1:before {
    background-position: center;
    background-repeat: no-repeat;
    transform: translateX(24px);
  }

  .slider1.round1 {
    border-radius: 34px;
  }

  .slider1.round1:before {
    background-color: gray;
    border-radius: 50%;
  }

  .switch {
    width: 55px;
    height: 27px;
    display: inline-block;
    position: relative;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    cursor: pointer;
    background-color: #ccc;
    transition: all .4s;
    position: absolute;
    inset: 0;
  }

  .slider:before {
    content: "";
    background: #fff url("https://i.ibb.co/FxzBYR9/night.png") center no-repeat;
    width: 35px;
    height: 35px;
    margin: auto 0;
    transition: all .4s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 15px #2020203d;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    background: #fff url("https://i.ibb.co/7JfqXxB/sunny.png") center no-repeat;
    transform: translateX(24px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    background-color: gray;
    border-radius: 50%;
  }

  .theme-light .order1, .theme-light .order2, .theme-light .order3 {
    color: #000;
  }

  .order1, .order2, .order3, .order4 {
    color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    display: flex;
  }

  .theme-light .modalChoice {
    color: #000;
  }

  .theme-light .modalDialog {
    background-color: #fff;
    border: 1px solid;
  }

  .theme-light h3 {
    color: #000;
  }

  .theme-light .footerTime {
    color: #000;
    background-color: #fff;
  }

  .theme-light .groceries {
    color: #000;
  }

  .theme-light {
    color: #000;
    background-color: #fff;
  }

  .theme-light .customerReadyOrders {
    background-color: #fff;
  }

  .theme-light .modalPRSPart, .theme-light .modalReadyPart {
    color: #000;
    background-color: #fff;
  }

  .theme-light .modalPRSOrders {
    background-color: #fff;
  }

  .theme-light .header, .theme-light .container-wrapper {
    color: #000;
    background-color: #fff;
  }

  .theme-light .container {
    color: #000;
    background-color: #fff;
    border-color: #000;
  }

  .theme-light .orderItem {
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
  }

  .theme-light .modalCloseButton {
    border: 1px solid #000;
  }

  .theme-light .upModalPart {
    color: #000;
    background-color: #fff;
  }

  .theme-light body {
    background-color: #fff;
  }

  .theme-light .parkedContainerMain, .theme-light .ordersContainer, .theme-light .footer, .theme-light .footerNavBar {
    color: #000;
    background-color: #fff;
  }

  .parderDiv {
    justify-content: space-around;
    display: flex;
  }

  .orderTable {
    margin-right: 10px;
    display: inline-block;
  }

  .orderStatusIf {
    display: inline-block;
  }

  .orderTimestamp {
    text-align: center;
    flex: 1;
  }

  .footerNavBar h3.active:after {
    content: "";
    background-color: var(--orange);
    width: 100%;
    height: 1px;
    display: block;
  }

  .customerPrepareOrders {
    background-color: #2b2b31;
    border-radius: 5px;
    flex-flow: column wrap;
    align-content: flex-start;
    width: 50%;
    height: 910px;
    display: flex;
    overflow: hidden scroll;
  }

  .customerPrepareOrders::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .customerready::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .customerready {
    box-sizing: border-box;
    border-radius: 5px;
    flex-flow: wrap;
    align-content: flex-start;
    width: 50%;
    padding: 10px;
    display: flex;
    overflow: hidden scroll;
  }

  .mainCustomerPart {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .upCustomerPart {
    justify-content: space-between;
    padding-top: 20px;
    display: flex;
  }

  .customerPart {
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    display: flex;
  }

  .customerLeftPart {
    color: #fff;
    text-align: center;
    background-color: #e70;
    border-radius: 5px;
    width: 50%;
    margin-right: 10px;
    padding: 10px;
    font-size: 40px;
  }

  .customerRightPart {
    background-color: var(--green);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    font-size: 40px;
  }

  .customerPreparePart {
    border: 1px solid var(--orange);
    color: #fff;
    text-align: center;
    background-color: #1f1c1c;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 4px;
    padding: 4px 0;
    font-size: 50px;
    display: flex;
    overflow: hidden;
  }

  .customerReadyPart {
    border: 1px solid var(--green);
    color: #fff;
    text-align: center;
    background-color: #1f1c1c;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 4px;
    padding: 4px 0;
    font-size: 50px;
    display: flex;
    overflow: hidden;
  }

  .kdsModePage {
    padding: 8px;
  }

  .modalChoiceBody {
    z-index: 1000;
    border-radius: 5px;
    flex-direction: column;
    flex: none;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modalChoiceTitle {
    text-align: center;
    padding-bottom: 30px;
    font-size: 22px;
  }

  .logout {
    background-color: var(--orange);
    color: #fff;
    border: none;
    border-radius: 50px;
    justify-content: center;
    width: 100px;
    margin-top: 40px;
    padding: 10px 20px;
    font-weight: bold;
    display: flex;
  }

  .modalDescription1 {
    font-size: 18px;
    line-height: 2;
  }

  .modalDescription {
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    display: flex;
  }

  .customerButton {
    color: #fff;
    cursor: pointer;
    background-color: #e70;
    border: none;
    border-radius: 5px;
    width: 110px;
    height: 80px;
    padding: 5px 6px;
  }

  .customerButton .sjefIcon svg {
    width: 40px;
    height: 40px;
  }

  .theme-light .customerPrepareOrders {
    background-color: #fff;
  }

  .theme-light .customerPreparePart, .theme-light .customerReadyPart {
    color: #000;
    background-color: #fff;
  }

  .kitchenButton {
    background-color: var(--green);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 110px;
    height: 80px;
    padding: 5px 6px;
  }

  .divov {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .groceries {
    color: #fce96a;
    font-size: 11px;
  }

  .groceriesRow {
    font-size: 12px;
  }

  .footerTime {
    color: #fff;
    flex-basis: 350px;
    justify-content: flex-start;
    width: 350px;
    max-width: 350px;
    padding: 1em;
    font-size: 11px;
    display: flex;
  }

  .theme-light .groceriesRow {
    color: red;
  }

  .theme-light .container-wrapper {
    color: #000;
    background-color: #fff;
  }

  .container-wrapper {
    flex-direction: column;
    display: flex;
  }

  .main-containers {
    width: 100%;
    max-width: 100%;
    padding: 4px 0;
    display: flex;
    overflow: scroll hidden;
  }

  .main-containers::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .containerFrame {
    width: inherit;
    flex-direction: column;
    height: 100%;
    display: flex;
  }

  .container {
    color: #f0f0f0;
    box-sizing: border-box;
    background-color: #272626;
    border: 2px solid #bbb;
    border-radius: 5px;
    flex-direction: column;
    width: 210px;
    height: 500px;
    margin-right: 5px;
    padding: 0;
    display: flex;
    position: relative;
  }

  .container.containerA {
    border-style: solid;
  }

  .container.containerA.almostDanger {
    border-color: var(--orange);
  }

  .container.containerA.danger {
    border-color: var(--red);
  }

  .container:last-child {
    margin-bottom: 100px;
  }

  .orderStatus.colorOfButton.open {
    color: var(--orange);
  }

  .orderStatus.colorOfButton.prepare {
    color: var(--green);
  }

  .lowOrderRightButton button.colorOfButton.open {
    background-color: var(--orange);
  }

  .lowOrderRightButton button.colorOfButton.prepare {
    background-color: var(--green);
  }

  .upPart {
    box-sizing: border-box;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 10px;
    display: flex;
  }

  .lowPart {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    display: flex;
    overflow-y: hidden;
  }

  .lowOrderMain {
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
  }

  .orderWrapper {
    flex-grow: 1;
    overflow-y: auto;
  }

  .order {
    width: 100%;
    padding-left: 0;
  }

  .orderWrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .lowOrderMainOrders {
    font-size: 16px;
  }

  .notes {
    color: #fce96a;
    font-size: 16px;
  }

  .lowOrder {
    grid-gap: 5px;
    flex-direction: row-reverse;
    padding: 0;
    display: flex;
  }

  .lowOrderRightButton {
    flex-grow: 1;
    width: 100%;
    height: 40px;
    position: relative;
    bottom: auto;
    right: auto;
  }

  .lowOrderRightButton button {
    background-color: var(--green);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .lowOrderLeftButton button {
    color: #fff;
    cursor: pointer;
    background-color: #4d4d4d;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
  }

  .lowOrderLeftButton {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }

  .idContainer, .orderName, .orderTime, .orderStatus {
    text-align: center;
    flex: 1;
    padding: 4px;
  }

  .serve-containers {
    width: 100%;
    max-width: 100%;
    padding: 4px 0;
    display: flex;
    overflow: scroll hidden;
  }

  .serve-containers::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  body {
    background-color: #19191e;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    overflow: hidden;
  }

  .main {
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  html {
    background-color: #19191e;
    width: 100%;
    height: 100%;
    padding: 30px 2px 2px;
    overflow: hidden;
  }

  h1, h3, h2 {
    color: #fff;
    margin: 0%;
  }

  ul {
    list-style-type: none;
  }

  .header {
    color: #fff;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-top: 25px;
    display: flex;
  }

  .headerFull {
    color: #fff;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    display: flex;
  }

  .leftPart {
    flex: 1;
  }

  .rightPart {
    text-align: right;
    flex-direction: row-reverse;
    flex: 1;
    align-items: baseline;
    display: flex;
  }

  .settings {
    position: relative;
  }

  .idContainer, .orderName, .orderTime {
    text-align: center;
    flex: 1;
    padding: 4px;
  }

  .orderStatus {
    text-align: center;
    flex: 1;
  }

  .idContainer {
    font-size: 22px;
  }

  .custom-select {
    min-width: 350px;
    position: relative;
  }

  select {
    appearance: none;
    color: #000;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #caced1;
    border-radius: .25rem;
    width: -webkit-fill-available;
    padding: .675em 6em .675em 1em;
    font-size: 1.15rem;
  }

  .custom-select:before, .custom-select:after {
    --size: .3rem;
    content: "";
    pointer-events: none;
    position: absolute;
    right: 1rem;
  }

  .custom-select:before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid black;
    top: 40%;
  }

  .custom-select:after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
    top: 55%;
  }

  .consolidatedOrders {
    width: 220px;
    height: 80%;
    padding: 1px 0;
    position: fixed;
    top: 65px;
    right: 0;
    overflow: hidden auto;
  }

  .consolidatedOrderMain {
    color: #f0f0f0;
    box-sizing: border-box;
    background-color: #272626;
    border-radius: 5px;
    place-items: center;
    width: 100%;
    height: 100%;
    margin-right: 5px;
    padding: 0;
    position: relative;
  }

  .theme-light .consolidatedOrderMain {
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
  }

  .consolidatedOrder {
    font-size: 16px;
  }

  .containeFrame {
    border-radius: 5px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    position: absolute;
    top: -10px;
    left: -10px;
  }

  .orderDescriptionServe {
    height: 370px;
  }

  .ordersList::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .ordersList {
    flex-wrap: wrap;
    max-width: 100%;
    padding: 4px 0;
    display: flex;
    overflow: scroll;
  }

  .container.active-container {
    border: 2px solid #faca15;
  }

  .ordersReopenButton button {
    color: #fff;
    cursor: pointer;
    background-color: #4d4d4d;
    border: none;
    border-radius: 5px;
    height: 30px;
    padding: 5px 10px;
  }

  .orderItem {
    color: #f0f0f0;
    border-style: solid;
    border-color: var(--orange);
    box-sizing: border-box;
    background-color: #272626;
    border-radius: 5px;
    flex: none;
    width: 210px;
    height: 110px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 0;
    position: relative;
  }

  .ordersReopenButton {
    text-align: center;
  }

  .orderItem::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .readyOrder::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .readyOrder {
    width: 100%;
    max-width: 100%;
    padding: 4px 0;
    display: flex;
    overflow: scroll hidden;
  }

  .readyOrderMain::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .idContainer {
    margin-bottom: 1px;
  }

  .readyServeButton button {
    color: #fff;
    cursor: pointer;
    background-color: #4d4d4d;
    border: none;
    border-radius: 5px;
    width: 145px;
    height: 40px;
    margin-top: 15px;
    margin-left: 48px;
    padding: 5px 10px;
  }

  .orderStatusServe {
    text-align: center;
    flex: 1;
    height: 40px;
    padding: 5px;
  }

  form {
    flex-direction: column;
    align-content: space-between;
    align-items: center;
    display: flex;
  }

  .form-field {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .signin-container {
    background-color: #19191e;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
  }

  .form-containr {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 4px 6px #0000001a;
  }

  .logo {
    width: 100px;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .signin-heading {
    text-align: center;
    color: #fff;
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .input-field {
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    margin-top: 20px;
    padding: 15px;
  }

  .form-fields {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .submit-button {
    color: #fff;
    cursor: pointer;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-top: 20px;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  .submit-button:hover {
    background-color: #45a049;
  }

  .submit-button:active {
    background-color: #3e8e41;
  }

  .header-title {
    flex-basis: 350px;
    justify-content: flex-end;
    width: 350px;
    max-width: 350px;
    padding: 1em;
    display: flex;
  }

  .footer .prepareFooterButton, .footer .readyFooterButton {
    margin-left: 10px;
  }

  .prepareFooterButton button {
    background-color: var(--orange);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 12px;
  }

  .readyFooterButton button {
    color: #fff;
    background-color: var(--green);
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 80px;
    height: 50px;
    padding: 5px 10px;
  }

  .footer {
    background-color: #272626;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .footerNavBar {
    color: #fff;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    display: flex;
  }

  .modalGroceries {
    color: #fce96a;
  }

  .modalOverlay {
    z-index: 999;
    position: fixed;
    inset: 0;
  }

  .modalDialog {
    z-index: 1000;
    background-color: #1a1a1a;
    border: 1px solid;
    border-radius: 5px;
    flex-direction: column;
    flex: none;
    width: 85vw;
    min-height: 300px;
    padding: 20px;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modalHeader {
    flex-flow: wrap;
    place-content: center space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
  }

  .modalKDSHeader {
    flex-flow: row-reverse wrap;
    place-content: center space-between;
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
  }

  .modalTitle {
    color: #fff;
    margin: 0;
  }

  .orderLines {
    color: #fff;
    padding: 0;
    list-style-type: none;
  }

  .modalChoiceFooter {
    justify-content: center;
    display: flex;
    bottom: 10px;
  }

  .modalFooter {
    text-align: right;
    flex-direction: row-reverse;
    display: flex;
    bottom: 10px;
  }

  .modalCloseButton {
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    width: 80px;
    height: 40px;
    padding: 5px 6px;
  }

  .modalAttributes {
    background-color: #272626;
    border-radius: 5px;
    padding: 10px;
  }

  .theme-light .modalAttributes {
    background-color: #fff;
  }

  .modalSettingsOverlay {
    z-index: 999;
    position: fixed;
    inset: 0;
  }

  .modalSettingsDialog {
    z-index: 1000;
    border: 1px solid var(--green);
    background-color: #1a1a1a;
    border-radius: 5px;
    flex-direction: column;
    flex: none;
    width: 80vw;
    min-height: 300px;
    padding: 20px;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modalSettingsTitlePrint, .modalSettingsHeader {
    background-color: #272626;
    border-radius: 5px;
    flex-flow: column wrap;
    place-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
    display: flex;
  }

  .modalDialog .modalBody {
    flex-grow: 1;
  }

  .modalDialog .modalFooter button:first-child:last-child {
    color: #fff;
    background: var(--orange);
    border: 2px solid var(--orange);
    border-radius: 50px;
    width: 100%;
    font-weight: bold;
  }

  .modalSettingsTitle {
    color: #fff;
    margin-bottom: 10px;
  }

  .modalSettingsOptions {
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    display: flex;
  }

  .modalSettingsOptions .selectOption {
    box-sizing: border-box;
    background-color: #272626;
    background: var(--black);
    color: #fff;
    border-radius: 50px;
    place-content: center;
    width: 80px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
  }

  .modalSettingsOptions .selectOption:nth-child(3n) {
    margin-right: 0;
  }

  .modalSettingsOptions .selectOption.selected {
    background: var(--green);
  }

  .modalSettingsOptionsLarge {
    box-sizing: border-box;
    background: var(--black);
    border-radius: 50px;
    width: 300px;
    margin-bottom: 20px;
    display: inline-flex;
  }

  .modalSettingsOptionsLarge .selectOption {
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
  }

  .modalSettingsOptionsLarge .selectOption.selected {
    background: var(--orange);
    border-radius: 50px;
  }

  .modalSettingsFooter {
    justify-content: center;
    display: flex;
    bottom: 10px;
  }

  .modalId {
    font-size: 24px;
  }

  .modalPRSOverlay {
    z-index: 999;
    background-color: #00000080;
    position: fixed;
    inset: 0;
  }

  .modalPRSDialog {
    z-index: 1000;
    background-color: #272626;
    border-radius: 5px;
    flex: none;
    max-width: 80%;
    max-height: 80%;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: auto;
    transform: translate(-50%, -50%);
  }

  .modalPRSHeader {
    margin-bottom: 10px;
  }

  .modalPRSTitle {
    color: #fff;
    margin: 0;
  }

  .modalPRSBody {
    color: #fff;
    padding-bottom: 10px;
  }

  .modalPRSFooter {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .modalPRSFooter button {
    flex: 1;
  }

  .modalPRSCloseButton {
    background-color: var(--green);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 85px;
    height: 40px;
    margin-right: 10px;
    padding: 5px 6px;
  }

  .modalPRSActionButton {
    background-color: var(--orange);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 85px;
    height: 40px;
    padding: 5px 6px;
  }

  .userMessage {
    justify-content: center;
    font-size: 35px;
    display: flex;
  }

  .modalUserOverlay {
    z-index: 999;
    background-color: #00000008;
    position: fixed;
    inset: 0;
  }

  .modalUserDialog {
    z-index: 1000;
    background-color: #272626;
    border-radius: 5px;
    flex: none;
    width: 400px;
    max-width: 80%;
    max-height: 80%;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: auto;
    transform: translate(-50%, -50%);
  }

  .modalUserHeader {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .modalUserTitle {
    color: #fff;
    margin: 0;
  }

  .modalUserBody {
    color: #fff;
    padding-bottom: 10px;
  }

  .modalUserFooter {
    text-align: right;
    justify-content: space-around;
    display: flex;
  }

  .modalUserCloseButton {
    color: #4d4d4d;
    cursor: pointer;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    width: 80px;
    height: 40px;
    margin-right: 10px;
    padding: 5px 6px;
  }

  .modalUserNoButton {
    color: #fff;
    cursor: pointer;
    background-color: red;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 60px;
    padding: 5px 6px;
  }

  .modalUserYesButton {
    color: #fff;
    cursor: pointer;
    background-color: #34d399;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 60px;
    padding: 5px 6px;
  }
}

@media screen and (width >= 769px) {
  .theme-light .selectOption, .theme-light .modalSettingsTitle {
    color: #fff;
  }

  .theme-light .options {
    background: #d3d3d3;
  }

  .modalSettingsOverlay {
    z-index: 999;
    position: fixed;
    inset: 0;
  }

  .modalSettingsDialog {
    z-index: 1000;
    background-color: #1a1a1a;
    border: 1px solid;
    border-radius: 5px;
    flex-direction: column;
    flex: none;
    width: 45vw;
    min-height: 300px;
    padding: 20px;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modalSettingsTitlePrint, .modalSettingsHeader {
    background-color: #272626;
    border-radius: 5px;
    flex-flow: column wrap;
    place-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
    display: flex;
  }

  .modalSettingsHeader:last-child {
    margin-bottom: 0;
  }

  .modalSettingsTitle {
    color: #fff;
    margin-bottom: 10px;
  }

  .modalSettingsOptions {
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 460px;
    padding: 10px;
    display: flex;
  }

  .modalSettingsOptions .selectOption {
    box-sizing: border-box;
    background-color: #272626;
    background: var(--black);
    color: #fff;
    border-radius: 50px;
    place-content: center;
    width: 80px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
  }

  .modalSettingsOptions .selectOption:nth-child(5n) {
    margin-right: 0;
  }

  .modalSettingsOptions .selectOption.selected {
    background: var(--green);
  }

  .modalSettingsOptionsLarge {
    box-sizing: border-box;
    background: var(--black);
    border-radius: 50px;
    width: 300px;
    margin-bottom: 20px;
    display: inline-flex;
  }

  .modalSettingsOptionsLarge .selectOption {
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
  }

  .modalSettingsOptionsLarge .selectOption.selected {
    background: var(--orange);
    border-radius: 50px;
  }

  .modalSettingsFooter {
    justify-content: center;
    display: flex;
    bottom: 10px;
  }

  .sortButton {
    color: var(--orange);
    background: none;
    border: 0;
    height: 30px;
  }

  .divdiv {
    padding: 10px;
  }

  .sortFilters {
    color: #fff;
  }

  .cui-overlay {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    display: flex;
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
  }

  .cui-toast {
    background-color: var(--green);
    color: #fff;
    z-index: 1000;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: auto;
    padding: 15px;
    display: flex;
    box-shadow: 0 4px 8px #0000001a;
  }

  .timerSelectZone {
    background-color: var(--green);
    color: #000;
  }

  .printModeSelectZone {
    background-color: var(--orange);
    color: #000;
  }

  .language-menu-container {
    display: inline-block;
    position: relative;
  }

  .language-menu {
    z-index: 1;
    margin: 0;
    padding-left: 10px;
    list-style-type: none;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .menu-toggle {
    color: #fff;
    cursor: pointer;
    text-align: center;
    background-color: #ccc;
    border: none;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px 16px;
    display: flex;
  }

  .menu-toggle:hover {
    background-color: #0056b3;
  }

  .custom-popover-container {
    display: inline-block;
    position: relative;
  }

  .custom-popover-content {
    z-index: 10;
    border: 1px solid #ddd;
    border-radius: 5px;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 4px 8px #0000001a;
  }

  .cui-icon-chevron-down {
    display: none;
  }

  .theme-light .modalDialog, .thema-light .modalDialog {
    background-color: #fff;
  }

  .modeSwitcher {
    flex-direction: row-reverse;
    place-content: center flex-end;
    align-items: center;
    display: flex;
  }

  .switchMode {
    width: 50px;
    height: 23px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
  }

  .switchMode input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider1:before {
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin: auto 0;
    transition: all .4s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 15px #2020203d;
  }

  input:checked + .slider1 {
    background-color: #2196f3;
  }

  input:focus + .slider1 {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider1:before {
    background-position: center;
    background-repeat: no-repeat;
    transform: translateX(24px);
  }

  .slider1.round1 {
    border-radius: 34px;
  }

  .slider1.round1:before {
    background-color: gray;
    border-radius: 50%;
  }

  .switch {
    width: 55px;
    height: 27px;
    display: inline-block;
    position: relative;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    cursor: pointer;
    background-color: #ccc;
    transition: all .4s;
    position: absolute;
    inset: 0;
  }

  .slider:before {
    content: "";
    background: #fff url("https://i.ibb.co/FxzBYR9/night.png") center no-repeat;
    width: 35px;
    height: 35px;
    margin: auto 0;
    transition: all .4s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 15px #2020203d;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    background: #fff url("https://i.ibb.co/7JfqXxB/sunny.png") center no-repeat;
    transform: translateX(24px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    background-color: gray;
    border-radius: 50%;
  }

  .theme-light .order1, .theme-light .order2, .theme-light .order3, .theme-light .order {
    color: #000;
  }

  .order {
    color: #fff;
    flex: 1;
  }

  .slider1 {
    cursor: pointer;
    background-color: #ccc;
    transition: all .4s;
    position: absolute;
    inset: 0;
  }

  .theme-light .modalChoice {
    color: #000;
  }

  .theme-light .modalDialog {
    background-color: #fff;
    border: 1px solid;
  }

  .theme-light h3 {
    color: #000;
  }

  .theme-light .footerTime {
    color: #000;
    background-color: #fff;
  }

  .theme-light .groceries {
    color: #000;
  }

  .theme-light {
    color: #000;
    background-color: #fff;
  }

  .theme-light .customerReadyOrders {
    background-color: #fff;
  }

  .theme-light .modalPRSPart, .theme-light .modalReadyPart {
    color: #000;
    background-color: #fff;
  }

  .theme-light .modalPRSOrders {
    background-color: #fff;
  }

  .theme-light .header, .theme-light .container-wrapper {
    color: #000;
    background-color: #fff;
  }

  .theme-light .container {
    color: #000;
    background-color: #fff;
    border-color: #000;
  }

  .theme-light .orderItem {
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
  }

  .theme-light .modalCloseButton {
    border: 1px solid #000;
  }

  .theme-light .upModalPart {
    color: #000;
    background-color: #fff;
  }

  .theme-light body {
    background-color: #fff;
  }

  .theme-light .parkedContainerMain, .theme-light .ordersContainer, .theme-light .footer, .theme-light .footerNavBar {
    color: #000;
    background-color: #fff;
  }

  .parderDiv {
    justify-content: space-around;
    display: flex;
  }

  .orderTable {
    margin-right: 10px;
    display: inline-block;
  }

  .orderStatusIf {
    display: inline-block;
  }

  .orderTimestamp {
    text-align: center;
    flex: 1;
  }

  .footerNavBar h3.active:after {
    content: "";
    background-color: var(--orange);
    width: 100%;
    height: 1px;
    display: block;
  }

  .customerPrepareOrders {
    background-color: #2b2b31;
    border-radius: 5px;
    flex-flow: column wrap;
    align-content: flex-start;
    width: 50%;
    height: 910px;
    display: flex;
    overflow: hidden scroll;
  }

  .customerPrepareOrders::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .customerready::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .customerready {
    border-radius: 5px;
    flex-flow: wrap;
    align-content: flex-start;
    width: 50%;
    display: flex;
    overflow: hidden scroll;
  }

  .mainCustomerPart {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .upCustomerPart {
    justify-content: space-between;
    padding-top: 20px;
    display: flex;
  }

  .customerPart {
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    display: flex;
  }

  .customerLeftPart {
    color: #fff;
    text-align: center;
    background-color: #e70;
    border-radius: 5px;
    width: 50%;
    margin-right: 10px;
    padding: 10px;
    font-size: 40px;
  }

  .customerRightPart {
    background-color: var(--green);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    font-size: 40px;
  }

  .customerPreparePart {
    color: #fff;
    text-align: center;
    background-color: #1f1c1c;
    border: 1px solid #e70;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 130px;
    margin-top: 10px;
    margin-left: 4px;
    padding: 4px 0;
    font-size: 60px;
    display: flex;
    overflow: hidden;
  }

  .customerReadyPart {
    border: 1px solid var(--green);
    color: #fff;
    text-align: center;
    background-color: #1f1c1c;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    width: calc(25% - 12px);
    margin-top: 10px;
    margin-left: 10px;
    padding: 4px 0;
    font-size: 60px;
    display: flex;
    overflow: hidden;
  }

  .kdsModePage {
    padding: 8px;
  }

  .modalChoiceBody {
    z-index: 1000;
    border-radius: 5px;
    flex-direction: column;
    flex: none;
    justify-content: space-between;
    width: 500px;
    padding: 20px;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modalChoiceTitle {
    text-align: center;
    padding-bottom: 30px;
    font-size: 22px;
  }

  .logout {
    background-color: var(--orange);
    color: #fff;
    border: none;
    border-radius: 50px;
    width: 100px;
    margin-top: 40px;
    padding: 10px 20px;
    font-weight: bold;
  }

  .modalDescription1 {
    font-size: 18px;
    line-height: 2.5;
  }

  .modalDescription {
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    display: flex;
  }

  .customerButton {
    color: #fff;
    cursor: pointer;
    background-color: #e70;
    border: none;
    border-radius: 5px;
    width: 110px;
    height: 80px;
    padding: 5px 6px;
  }

  .customerButton .sjefIcon svg {
    width: 40px;
    height: 40px;
  }

  .theme-light .customerPrepareOrders {
    background-color: #fff;
  }

  .theme-light .customerPreparePart, .theme-light .customerReadyPart {
    color: #000;
    background-color: #fff;
  }

  .kitchenButton {
    background-color: var(--green);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 110px;
    height: 80px;
    padding: 5px 6px;
  }

  .divov {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .groceries {
    color: #fce96a;
    font-size: 11px;
  }

  .groceriesRow {
    font-size: 12px;
  }

  .footerTime {
    color: #fff;
    flex-basis: 350px;
    justify-content: flex-start;
    width: 350px;
    max-width: 350px;
    padding: 1em;
    font-size: 12px;
    display: flex;
  }

  .footerTime .footerTimeInner {
    background: var(--green);
    border-radius: 50px;
    padding: 10px 20px;
    font-weight: bold;
  }

  .theme-light .footerTime .footerTimeInner {
    color: #fff;
  }

  .theme-light .groceriesRow {
    color: red;
  }

  .container-wrapper {
    flex-direction: column;
    display: flex;
  }

  .main-containers {
    overflow-wrap: scroll;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    max-width: 100%;
    height: 88vh;
    padding: 4px 0;
    display: flex;
    overflow-y: auto;
  }

  .webSiteVersion.open .main-containers {
    width: calc(100% - 220px);
  }

  .main-containers::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .colored {
    background: #00f;
  }

  .containerFrame {
    width: inherit;
    flex-direction: column;
    height: 100%;
    display: flex;
  }

  .container {
    color: #f0f0f0;
    box-sizing: border-box;
    background-color: #272626;
    border: 2px solid #bbb;
    border-radius: 5px;
    flex: none;
    width: 210px;
    height: 500px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 0;
    position: relative;
  }

  .container.containerA {
    border-style: solid;
  }

  .container.containerA.almostDanger {
    border-color: var(--orange);
  }

  .container.containerA.danger {
    border-color: var(--red);
  }

  .container:last-child {
    margin-bottom: 100px;
  }

  .orderStatus.colorOfButton.open {
    color: var(--orange);
  }

  .orderStatus.colorOfButton.prepare {
    color: var(--green);
  }

  .lowOrderRightButton button.colorOfButton.open {
    background-color: var(--orange);
  }

  .lowOrderRightButton button.colorOfButton.prepare {
    background-color: var(--green);
  }

  .upPart {
    box-sizing: border-box;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 10px;
    display: flex;
  }

  .lowPart {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    display: flex;
    overflow-y: hidden;
  }

  .lowOrderMain {
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
  }

  .orderWrapper {
    flex-grow: 1;
    overflow-y: auto;
  }

  .order {
    width: 100%;
    height: 100%;
    margin: auto;
    padding-left: 0;
  }

  .orderWrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .lowOrderMainOrders {
    font-size: 16px;
  }

  .notes {
    color: #fce96a;
    font-size: 16px;
  }

  .lowOrder {
    grid-gap: 5px;
    flex-direction: row-reverse;
    width: 100%;
    padding: 0;
    display: flex;
  }

  .lowOrderRightButton {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  .lowOrderRightButton button {
    background-color: var(--green);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .lowOrderLeftButton {
    width: 40%;
    height: 100%;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }

  .lowOrderLeftButton button {
    color: #fff;
    cursor: pointer;
    background-color: #4d4d4d;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
  }

  .serve-containers {
    overflow-wrap: scroll;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    max-width: 100%;
    height: 88vh;
    padding: 4px 0;
    display: flex;
    overflow-y: auto;
  }

  .serve-containers::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  body {
    background-color: #19191e;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    overflow: hidden;
  }

  .main {
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  html {
    background-color: #19191e;
    width: calc(100% - 16px);
    height: 100%;
    padding: 8px;
    overflow: hidden;
  }

  h1, h3, h2 {
    color: #fff;
    margin: 0%;
  }

  ul {
    list-style-type: none;
  }

  .header {
    color: #fff;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-top: 10px;
    display: flex;
  }

  .headerFull {
    color: #fff;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    display: flex;
  }

  .leftPart {
    flex: 1;
    align-items: center;
    display: flex;
  }

  .middlePart {
    background-image: url("sjef_logo-white.5051d464.png");
    background-position: center;
    background-size: 84px;
    width: 84px;
    height: 29px;
  }

  .theme-light .middlePart {
    background-image: url("sjef_logo.331a9f06.png");
    background-position: center;
    background-size: 84px;
  }

  .rightPart {
    text-align: right;
    flex-direction: row-reverse;
    flex: 1;
    align-items: center;
    margin-right: 10px;
    display: flex;
  }

  .settings {
    position: relative;
  }

  .idContainer, .orderName, .orderTime {
    text-align: center;
    flex: 1;
    padding: 4px;
  }

  .orderStatus {
    text-align: center;
    flex: 1;
  }

  .idContainer {
    font-size: 22px;
  }

  .openOrderFooter, .parkedOrderFooter {
    margin-right: 150px;
  }

  .custom-select {
    min-width: 350px;
    position: relative;
  }

  select {
    appearance: none;
    color: #000;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #caced1;
    border-radius: .25rem;
    width: -webkit-fill-available;
    padding: .675em 6em .675em 1em;
    font-size: 1.15rem;
  }

  .custom-select:before, .custom-select:after {
    --size: .3rem;
    content: "";
    pointer-events: none;
    position: absolute;
    right: 1rem;
  }

  .custom-select:before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid black;
    top: 40%;
  }

  .custom-select:after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
    top: 55%;
  }

  .consolidatedOrders {
    width: 220px;
    height: 80%;
    padding: 1px 0;
    position: fixed;
    top: 65px;
    right: 0;
    overflow: hidden auto;
  }

  .consolidatedOrderMain {
    color: #f0f0f0;
    box-sizing: border-box;
    background-color: #272626;
    border-radius: 5px;
    place-items: center;
    width: 100%;
    height: 100%;
    margin-right: 5px;
    padding: 10px;
    position: relative;
  }

  .consolidatedOrderMain h3 {
    margin-bottom: 20px;
  }

  .theme-light .consolidatedOrderMain {
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
  }

  .consolidatedOrder {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .consolidatedOrder:last-child {
    margin-bottom: 0;
  }

  .containeFrame {
    border-radius: 5px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    position: absolute;
    top: -10px;
    left: -10px;
  }

  .orderDescriptionServe {
    height: 370px;
  }

  .ordersList::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .ordersList {
    flex-wrap: wrap;
    max-width: 100%;
    padding: 4px 0;
    display: flex;
    overflow: scroll;
  }

  .container.active-container {
    border: 2px solid #faca15;
  }

  .ordersReopenButton button {
    color: #fff;
    cursor: pointer;
    background-color: #4d4d4d;
    border: none;
    border-radius: 5px;
    height: 30px;
    padding: 5px 10px;
  }

  .orderItem {
    color: #f0f0f0;
    border-style: solid;
    border-color: var(--orange);
    box-sizing: border-box;
    background-color: #272626;
    border-radius: 5px;
    flex: none;
    width: 210px;
    height: 110px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 0;
    position: relative;
  }

  .ordersReopenButton {
    text-align: center;
  }

  .orderItem::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .readyOrder::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .readyOrder {
    width: 100%;
    max-width: 100%;
    padding: 4px 0;
    display: flex;
    overflow: scroll hidden;
  }

  .readyOrderMain::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .idContainer {
    margin-bottom: 1px;
  }

  .readyServeButton button {
    color: #fff;
    cursor: pointer;
    background-color: #4d4d4d;
    border: none;
    border-radius: 5px;
    width: 145px;
    height: 40px;
    margin-top: 15px;
    margin-left: 48px;
    padding: 5px 10px;
  }

  .orderStatusServe {
    text-align: center;
    flex: 1;
    height: 40px;
    padding: 5px;
  }

  .signin-container {
    background-color: #19191e;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
  }

  .form-containr {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 4px 6px #0000001a;
  }

  .logo {
    width: 100px;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .signin-heading {
    text-align: center;
    color: #fff;
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .input-field {
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
  }

  .submit-button {
    color: #fff;
    cursor: pointer;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .submit-button:hover {
    background-color: #45a049;
  }

  .submit-button:active {
    background-color: #3e8e41;
  }

  .header-title {
    flex-basis: 350px;
    justify-content: flex-end;
    width: 350px;
    max-width: 350px;
    padding: 1em;
    display: flex;
  }

  .footer .prepareFooterButton, .footer .readyFooterButton {
    margin-left: 10px;
  }

  .prepareFooterButton button {
    background-color: var(--orange);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: bold;
  }

  .readyFooterButton button {
    color: #fff;
    background-color: var(--green);
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 80px;
    height: 50px;
    padding: 5px 10px;
  }

  .footer {
    background-color: #272626;
    place-content: center space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .footerNavBar {
    color: #fff;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    font-size: 13px;
    display: flex;
  }

  .modalGroceries {
    color: #fce96a;
  }

  .modalAttributes {
    background-color: #272626;
    border-radius: 5px;
    padding: 10px;
  }

  .theme-light .modalAttributes {
    background-color: #fff;
  }

  .modalOverlay {
    z-index: 999;
    position: fixed;
    inset: 0;
  }

  .modalDialog {
    z-index: 1000;
    background-color: #1a1a1a;
    border: 1px solid;
    border-radius: 5px;
    flex-direction: column;
    flex: none;
    width: 500px;
    min-height: 300px;
    padding: 20px;
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modalDialog .modalBody {
    flex-grow: 1;
  }

  .modalDialog .modalFooter button:first-child:last-child {
    color: #fff;
    background: var(--orange);
    border: 2px solid var(--orange);
    border-radius: 50px;
    width: 100%;
    font-weight: bold;
  }

  .modalHeader {
    flex-flow: wrap;
    place-content: center space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
  }

  .modalKDSHeader {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
  }

  .modalDescription .order {
    color: #fff;
    flex-direction: column-reverse;
    flex: 1;
    align-items: center;
    font-weight: bold;
    display: flex;
  }

  .modalDescription .order button {
    margin-bottom: 10px;
  }

  .modalKDSHeader button {
    margin: 0 10px 0 0;
  }

  .modalTitle {
    color: #fff;
    margin: 0;
  }

  .orderLines {
    color: #fff;
    padding: 0;
    list-style-type: none;
  }

  .modalChoiceFooter {
    justify-content: center;
    display: flex;
    bottom: 10px;
  }

  .modalFooter {
    text-align: right;
    flex-direction: row-reverse;
    display: flex;
    bottom: 10px;
  }

  .modalCloseButton {
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    width: 80px;
    height: 40px;
    padding: 5px 6px;
  }

  .modalId {
    font-size: 24px;
  }

  .modalPRSOverlay {
    z-index: 999;
    background-color: #00000080;
    position: fixed;
    inset: 0;
  }

  .modalPRSDialog {
    z-index: 1000;
    background-color: #272626;
    border-radius: 5px;
    flex: none;
    min-width: 400px;
    max-width: 80%;
    max-height: 80%;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: auto;
    transform: translate(-50%, -50%);
  }

  .theme-light .modalPRSDialog {
    background-color: #fff;
  }

  .theme-light .modalDescription .order {
    color: var(--black);
  }

  .theme-light h4 {
    color: #000;
  }

  .modalPRSHeader {
    margin-bottom: 10px;
  }

  .modalPRSTitle {
    color: #fff;
    margin: 0;
  }

  .modalPRSBody {
    color: #fff;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .modalPRSBody h4 {
    font-weight: 400;
  }

  .modalPRSBody span {
    font-size: 14px;
  }

  .modalPRSFooter {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .modalPRSFooter button {
    text-transform: capitalize;
    border-radius: 50px;
    flex: 1;
    font-weight: bold;
  }

  .modalPRSCloseButton {
    background-color: var(--green);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 85px;
    height: 40px;
    margin-right: 10px;
    padding: 5px 6px;
  }

  .modalPRSActionButton {
    background-color: var(--orange);
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 85px;
    height: 40px;
    padding: 5px 6px;
  }

  .userMessage {
    justify-content: center;
    font-size: 35px;
    display: flex;
  }

  .modalUserOverlay {
    z-index: 999;
    background-color: #00000008;
    position: fixed;
    inset: 0;
  }

  .modalUserDialog {
    z-index: 1000;
    background-color: #272626;
    border-radius: 5px;
    flex: none;
    width: 400px;
    max-width: 80%;
    max-height: 80%;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: auto;
    transform: translate(-50%, -50%);
  }

  .modalUserHeader {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .modalUserTitle {
    color: #fff;
    margin: 0;
  }

  .modalUserBody {
    color: #fff;
    padding-bottom: 10px;
  }

  .modalUserFooter {
    text-align: right;
    justify-content: space-around;
    display: flex;
  }

  .modalUserCloseButton {
    color: #4d4d4d;
    cursor: pointer;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    width: 80px;
    height: 40px;
    margin-right: 10px;
    padding: 5px 6px;
  }

  .modalUserNoButton {
    color: #fff;
    cursor: pointer;
    background-color: red;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 60px;
    padding: 5px 6px;
  }

  .modalUserYesButton {
    color: #fff;
    cursor: pointer;
    background-color: #34d399;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 60px;
    padding: 5px 6px;
  }
}

@media screen and (height >= 1024px) {
  .container {
    color: #f0f0f0;
    box-sizing: border-box;
    background-color: #272626;
    border: 2px solid #bbb;
    border-radius: 5px;
    flex: none;
    width: 210px;
    height: 650px;
    margin-right: 5px;
    padding: 0;
    position: relative;
  }
}
/*# sourceMappingURL=index.3c671f6c.css.map */
